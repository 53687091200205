import { TakeActionDropdownData } from 'components/TakeActionDropdown/types/TakeActionDropdownData';
import { searchGetEventsRoutePath } from 'modules/search/routing/helpers/searchGetEventsRoutePath';
import { searchGetVolopsRoutePath } from 'modules/search/routing/helpers/searchGetVolopsRoutePath';

export const takeActionDropdownDataEs: TakeActionDropdownData = {
  search: {
    title: 'Encuentra',
    items: [
      {
        type: 'volop',
        title: 'Voluntariado',
        href: searchGetVolopsRoutePath({ lang: 'es' }),
      },
      {
        type: 'event',
        title: 'Asiste a un Evento',
        href: searchGetEventsRoutePath({ lang: 'es' }),
      },
    ],
  },
  resources: {
    title: 'Recursos',
    items: [
      {
        type: 'recipes-for-action',
        title: 'Recetas para la Acción',
        description:
          'Una colección de ideas prácticas para generar cambios positivos alrededor del mundo.',
        href: '/es/recetas-para-accion',
      },
      {
        type: 'blog',
        title: 'Blog Idealistas',
        description:
          'Eventos, convocatorias, consejos prácticos y mucho + en el sector de impacto en Iberoamérica',
        href: 'es/blog',
      },
      {
        type: 'knowledge-base',
        title: 'Centro de Recursos para Recaudación de Fondos',
        description:
          'Consejos prácticos que te facilitarán la busca de recursos para tu organización o proyecto.',
        href: '/es/recursos-recaudacion-fondos',
      },
    ],
  },
};
