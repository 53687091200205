import styled, { css } from 'styled-components';

import { VersionedRouterLink } from 'components/VersionedLink/VersionedRouterLink';
import { colors, cssBreakpoints, shadows, transition } from 'theme/theme';

export const dropdownFoldMenuColumnsCountVar =
  '--dropdown-fold-menu-columns-count';
export const dropdownFoldMenuButtonLinkWidth = 185;
export const dropdownFoldMenuButtonLinksGap = 3;

export const DropdownFoldMenuHolder = styled.menu`
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    width: 100%;
    left: 0;
    border-top: 1px solid ${colors.selectionGrey};
    z-index: 99;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    border-radius: 8px;
    margin-top: 8px;
    box-shadow: ${shadows.toolbar};
    left: -92px;
    z-index: 101;
    background: ${colors.white};
  }
`;

export const DropdownMobileBackdrop = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background: ${colors.calloutGrey};
  z-index: -1;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: none;
  }
`;

/*
 * Panel
 */

export const DropdownFoldMenuPanel = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    background: ${colors.white};
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px 24px 24px;
    flex-shrink: 0;
  }
`;

export const DropdownFoldMenuPanelTitle = styled.header`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    color: ${colors.lightContentGrey};
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.11em;
    margin-bottom: 22px;
  }
`;

export const DropdownFoldBottomMenuPanel = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }
`;

/*
 * Mobile Link
 */

const dropdownFoldMenuMobileLinksHolderCss = css`
  display: flex;
  flex-direction: column;
`;

const dropdownFoldMenuMobileLinkCss = css`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${colors.mediumContentGrey};
  height: 64px;
  border-bottom: 1px solid ${colors.selectionGrey};
  font-size: 16px;
  padding-left: 32px;
`;

/*
 * Button Link
 */

export const DropdownFoldMenuPanelButtonLinksHolder = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    ${dropdownFoldMenuMobileLinksHolderCss};
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: flex;
    flex-flow: column wrap;
    gap: ${dropdownFoldMenuButtonLinksGap}px;
    max-height: 160px;

    /* This width needs to be set explicitly because flex-wrap causes the browser to not calculate the container width properly */
    width: calc(
      ${dropdownFoldMenuButtonLinkWidth}px *
        var(${dropdownFoldMenuColumnsCountVar}) +
        ${dropdownFoldMenuButtonLinksGap}px *
        (var(${dropdownFoldMenuColumnsCountVar}) - 1)
    );
  }
`;

export const DropdownFoldMenuButtonLink = styled(VersionedRouterLink)`
  transition: ${transition};
  text-decoration: none;

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    ${dropdownFoldMenuMobileLinkCss};
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: inline-flex;
    align-items: center;
    height: 48px;
    width: ${dropdownFoldMenuButtonLinkWidth}px;
    background: ${colors.backgroundBlue};
    gap: 12px;
    color: ${colors.brandBlue};
    padding: 0 16px;
    line-height: 20.5px;

    &:hover {
      background: ${colors.calloutGrey};
    }
  }
`;

/*
 * Rich Link
 */

export const DropdownFoldMenuRichLinksHolder = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    ${dropdownFoldMenuMobileLinksHolderCss};
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: flex;
    flex-flow: column wrap;
    gap: 16px;
    max-height: 200px;
    width: max-content;
  }
`;

export const DropdownFoldMenuRichLink = styled(VersionedRouterLink)<{
  $background: string;
  $backgroundHover: string;
}>`
  text-decoration: none;
  transition: ${transition};

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    border-radius: 8px;
    align-items: flex-start;
    gap: 12px;
    margin: -6px;
    padding: 6px;
    padding-left: 60px;
    width: 360px;
    display: inline-flex;

    background-image: ${(props) => props.$background};
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-position: 10px 6px;

    &:hover {
      background-color: ${colors.calloutGrey};
      background-image: ${(props) => props.$backgroundHover};
    }
  }
`;

export const DropdownFoldMenuRichContentHolder = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    ${dropdownFoldMenuMobileLinkCss};
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    flex-direction: column;
    gap: 8px;
    display: flex;
  }
`;

export const DropdownFoldMenuRichLinkTitle = styled.div`
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    color: ${colors.mediumContentGrey};
    font-size: 16px;
  }
`;

export const DropdownFoldMenuRichLinkDescription = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    color: ${colors.lightContentGrey};
    font-size: 14px;
    line-height: 18.5px;
  }
`;
